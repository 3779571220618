import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { WarantyInsuranceComponent } from './warantyInsurance.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [WarantyInsuranceComponent],
  exports: [
    WarantyInsuranceComponent
  ],
  providers: [
  ]
})
export class WarantyInsuranceModule { }
