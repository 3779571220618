import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CheckPointService, Constant, PermWorkRecordService, SlipService } from 'src/app/shared';
import { AdvMoneyService } from 'src/app/shared/service/advMoney.service';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-working',
  templateUrl: './working.component.html',
  styleUrls: ['./working.component.css']
})
export class WorkingComponent implements OnInit {

  options: any = {
    locale: { format: 'MM-YYYY' },
    alwaysShowCalendars: false,
    singleDatePicker: true
  };

  emp;
  searchForm: FormGroup;
  addForm: FormGroup;
  yearList;
  currentYear;
  dayList = [];
  customerList =  [];
  employeeList = [];
  peroidType = 0;
  peroidText = '';
  peroidMonth = 0;
  workViewList = [];
  yearSeach:number = 0;
  empPrint;

  checkPointModel = {
    'peroid':'06-01-2022',
    'customerList':[]
  };
  
  checkPointModelCompare = {
    'peroid':'06-01-2022',
    'customerList':[]
  };
  checkPointRecheck = [];
  advList = [];

  constructor(
    private fb: FormBuilder,
    private permWorkRecordService: PermWorkRecordService,
    private checkPointService: CheckPointService,
    private spinner: NgxSpinnerService,
    private slipService: SlipService,
    private constant: Constant,
    private advMoneyService: AdvMoneyService
  ) { 
    this.searchForm = fb.group({
      'month':'1-0',
      'year': [new Date().getFullYear()],
      'customerId': [''],
    });
  }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    this.generateYearList();
    //this.getGuard();
    this.emp = JSON.parse(localStorage.getItem('tisToken'));
    this.getAdvByEmployeeIdV2(this.emp.id);
  } 


  getAdvByEmployeeIdV2(empId){
    this.advList = [];
    this.advMoneyService.getByEmployeeByIdV2(empId).subscribe(res=>{
      this.advList = res;
      console.log(this.advList);
    });
  }

  generateYearList(){
    this.yearList = [];
    let currentYear = new Date().getFullYear();
    console.log('currentYear : ' + currentYear);
    //generate next 3 year
    for(let i=0;i<5;i++){
      let year = 
      {
        'enYear':currentYear-i,
        'thYear':(currentYear-i) + 543
      };
      this.yearList.push(year);
    }
  } 

  getGuard(){
    this.employeeList = [];
    this.permWorkRecordService.getGuard().subscribe(res=>{
      this.employeeList = res;
      //console.log(this.employeeList);
    });
  }

  searchCheckPoint(){
    this.spinner.show();
    this.empPrint = null;
    this.checkPointModel = {
      'peroid':'06-01-2022',
      'customerList':[]
    };
    this.checkPointRecheck = [];
    this.dayList = [];
    this.workViewList = [];

    this.yearSeach = Number(this.searchForm.get('year').value) + 543;
    let splitMonth = this.searchForm.get('month').value.split('-');
    this.peroidMonth = splitMonth[0];
    this.peroidType = splitMonth[1];
    let dateStart = 1;
    let dateEnd = 15;
    if(this.peroidType == 1){
      dateStart = 16;
      if(this.peroidMonth == 2){
        dateEnd = 28;
      }else if(this.peroidMonth == 4 || this.peroidMonth == 6 
        || this.peroidMonth == 9 || this.peroidMonth == 11){
          dateEnd = 30;
      }else{
        dateEnd = 31;
      } 
    }
    for(let i=dateStart;i<=dateEnd;i++){
      this.dayList.push(i);
    }
    let month = splitMonth[0];
    let checkPoint = {
      'month':month,
      'year':this.searchForm.get('year').value
    };
    console.log(checkPoint)
    this.checkPointService.getCheckPoint(checkPoint).subscribe(data=>{
      //console.log(data);
      if(data.data != null){
        this.checkPointModel = JSON.parse(data.data['json_data']);
        this.checkPointModelCompare = this.checkPointModel;
        console.log(this.checkPointModel);
        this.checkPointRecheck.push(this.emp);

        for(let k=0;k<this.checkPointRecheck.length;k++){
          let customerWorkList = [];
          for(let i=0;i<this.checkPointModel.customerList.length;i++){
            for(let j=0;j<this.checkPointModel.customerList[i].employeeList.length;j++){
              if(this.checkPointModel.customerList[i].employeeList[j].employeeId == this.checkPointRecheck[k].id){
                console.log(this.checkPointModel.customerList[i].employeeList[j])
                if(this.empPrint==null){
                  this.empPrint = this.checkPointModel.customerList[i].employeeList[j];
                } 
                let workList = [];
                for(let m=0;m<this.checkPointModel.customerList[i].employeeList[j].workList.length;m++){
                  let workItem = this.checkPointModel.customerList[i].employeeList[j].workList[m];
                  if(workItem.date >= dateStart && workItem.date <= dateEnd){
                    workList.push(workItem);
                  }
                }
                let obj = {
                  'customerId':this.checkPointModel.customerList[i].customerId,
                  'customerName':this.checkPointModel.customerList[i].customerName,
                  'workList':workList
                }
                customerWorkList.push(obj);
              }
            }
          }
          this.checkPointRecheck[k]['customerWorkList'] = customerWorkList;
        }
        console.log(this.checkPointRecheck);
        //work view list
        this.dayList.forEach(item => {
          let day = {'day':item,cust:[]}
          for(let i=0;i<this.checkPointRecheck[0].customerWorkList.length;i++){
            for(let j=0;j<this.checkPointRecheck[0].customerWorkList[i].workList.length;j++){
              let work = this.checkPointRecheck[0].customerWorkList[i].workList[j];
              if(work.date == item && work.status != ''){
                day.cust.push(
                  {
                    'customerName': this.checkPointRecheck[0].customerWorkList[i].customerName,
                    'work':work
                  }
                );
              }
            }
          }
          this.workViewList.push(day);
        });

        console.log(this.workViewList);
        this.spinner.hide();
      }else{
        this.spinner.hide();
      }
    });
  }

  printSlip(){
    console.log('print slip')
    this.spinner.show();
    //ประกันสังคม
    let sumSociety = 0.00;
    //customer name
    let customerName = '';
    if(this.workViewList.length>0){
      for(let i=0;i<this.workViewList.length;i++){
        if(this.workViewList[i].cust.length>0){
          customerName = this.workViewList[i].cust[0].customerName;
          break;
        }
      }
      //console.log(this.workViewList[0])
      //console.log(this.workViewList[0].cust[0])
      //customerName = this.workViewList[0].cust[0].customerName;
    }
    //total income - deduct
    let totalIncome = 0.00;
    let totalDeduct = 0.00;
    // ----------------- income list -----------------
    let incomeList = [];
    //001 and 002
    let obj001 = {'code':'001','name':'ค่าแรง(D)','quantity':0,'price':0.00,'total':0.00,'editType':0,'incomeType':'FIX'};
    let obj002 = {'code':'002','name':'ค่าแรง(N)','quantity':0,'price':0.00,'total':0.00,'editType':0,'incomeType':'FIX'};
    let countDay = 0;
    let priceDay = 550;
    let totalDay = 0.00;
    let countNigth = 0;
    let priceNigth = 550;
    let totalNigth = 0.00;
    this.workViewList.forEach(item => {
      console.log(item)
      for(let i=0;i<item.cust.length;i++){
        if(item.cust[i].work.status == 'W' && item.cust[i].work.day){
          countDay++;
          priceDay = Number(item.cust[i].work.waged);
          totalDay = totalDay+priceDay;
          sumSociety = sumSociety + ((priceDay/12)*8);
        }
        if(item.cust[i].work.status == 'W' && item.cust[i].work.nigth){
          countNigth++;
          priceNigth = Number(item.cust[i].work.wagen); 
          totalNigth = totalNigth+priceNigth;
          sumSociety = sumSociety + ((priceNigth/12)*8);
        }
      }
    });
    obj001.quantity = countDay;
    obj001.price = priceDay;
    obj001.total = totalDay;
    incomeList.push(obj001);
    obj002.quantity = countNigth;
    obj002.price = priceNigth;
    obj002.total = totalNigth;
    incomeList.push(obj002);
    console.log(incomeList);
    totalIncome = totalIncome + totalDay + totalNigth;
    console.log(totalIncome);
    //End 001 and 002

    //-----------------End income -----------------


    //----------------- work list -----------------
    let workList = [];
    this.workViewList.forEach(item => {
      if(item.cust.length>0){
        for(let i=0;i<item.cust.length;i++){
          workList.push(item.cust[i].work)
        }
      }else{
        workList.push({
          'date':item.day,
          'status':''
        })
      }
    });
    console.log(workList);
    // ----------------- End work list -----------------

    // ----------------- deduct list -----------------
    let deductList = [];

    //001 ประกันสังคม
    let societyPercentText = '5';
    let societyPercent = 0.05;
    let summarySociety = 0.00;
    console.log('sumSociety:' + sumSociety)
    summarySociety = Math.ceil((sumSociety) * (societyPercent));
    console.log('summarySociety:' + summarySociety)
    totalDeduct = totalDeduct + summarySociety;
    deductList.push({'code':'001','name':'ประกันสังคม('+societyPercentText+'%)','quantity':1,'price':0,'total':summarySociety,'editType':0,'incomeType':'MANUAL'});
    
    //005 เบิกล่วงหน้า  *** ต้องไปอ่านค่าจากหน้า manange การเบิก
    let deductExtendList05 = [];
    let priceExtend05 = 0.00;
    let totalExtend05 = 0.00;
    this.advList.forEach(item => {
      for(let i=0;i<item.items.length;i++){
        let advItem = item.items[i];
        let splitPeroid = advItem.paid_peroid.split('_');
        let splitMonth = splitPeroid[0].split('-');
        if(Number(splitMonth[0]) == this.peroidMonth 
        && Number(splitMonth[1]) == this.peroidType
        && Number(splitPeroid[1]) == Number(this.searchForm.get('year').value)){
          deductExtendList05.push({'desc':'เบิก '+this.removeTimeOfDate(item.pick_date), 'amount':advItem.amount});
          priceExtend05 = advItem.amount;
          totalExtend05 = totalExtend05 + advItem.amount;
          totalDeduct = totalDeduct + advItem.amount;
        }
      }
    });
    deductList.push({'code':'005','name':'เบิกล่วงหน้า','quantity':1,'price':priceExtend05,'total':totalExtend05,'deductExtendList':deductExtendList05,'editType':0,'incomeType':'FIX'});
    console.log(deductList);
    // ----------------- End  deduct list -----------------

    //return;

    let request = {
      "customerName":customerName,
      "employeeName":this.emp.first_name
      +" "+this.emp.last_name,
      "incomeList":incomeList,
      "deductList":deductList,
      "totalIncome":totalIncome,
      "totalDeduct":totalDeduct,
      "peroid": this.getPeriod(),
      "deductExtendList002": [],
      "deductExtendList003": [],
      "deductExtendList004": [],
      "deductExtendList005": deductExtendList05,
      "workList": workList,
      "employeeId": this.emp.id
    };
    console.log(request);
    this.slipService.cashSlipMonthly(request).subscribe(res=>{
      console.log(res)
      console.log(res.data);
      this.spinner.hide();
      window.open(this.constant.API_ENDPOINT + "/slip/download/monthly/"+this.emp.id);
    });
  }


  getPeriod(){
    let peroid = '';
    if(this.peroidMonth == 1){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-31 ';
      }
      peroid = peroid + 'มกราคม ';
    }else if(this.peroidMonth == 2){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-29 ';
      }
      peroid = peroid + 'กุมภาพันธ์ ';
    }else if(this.peroidMonth == 3){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-31 ';
      }
      peroid = peroid + 'มีนาคม ';
    }else if(this.peroidMonth == 4){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-30 ';
      }
      peroid = peroid + 'เมษายน ';
    }else if(this.peroidMonth == 5){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-31 ';
      }
      peroid = peroid + 'พฤษภาคม ';
    }else if(this.peroidMonth == 6){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-30 ';
      }
      peroid = peroid + 'มิถุนายน ';
    }else if(this.peroidMonth == 7){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-31 ';
      }
      peroid = peroid + 'กรกฎาคม ';
    }else if(this.peroidMonth == 8){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-31 ';
      }
      peroid = peroid + 'สิงหาคม ';
    }else if(this.peroidMonth == 9){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-30 ';
      }
      peroid = peroid + 'กันยายน ';
    }else if(this.peroidMonth == 10){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-30 ';
      }
      peroid = peroid + 'ตุลาคม ';
    }else if(this.peroidMonth == 11){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-30 ';
      }
      peroid = peroid + 'พฤศจิกายน ';
    }else if(this.peroidMonth == 12){
      if(this.peroidType == 0){
        peroid = peroid + '1-15 ';
      }else if(this.peroidType == 1){
        peroid = peroid + '16-30 ';
      }
      peroid = peroid + 'ธันวาคม ';
    }
    peroid = peroid + this.searchForm.get('year').value;
    return peroid;
  }

  removeTimeOfDate(dateString){
    let split = dateString.split(' ');
    return split[0];
  }
}
