import { Component, OnInit ,ViewChild, ElementRef, NgZone} from '@angular/core';
import { MapsAPILoader } from '@agm/core';
declare var jquery: any;
declare var $: any;

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmployeeService, CustomerService, 
  MasterDataService, PermWorkRecordService, 
  CalendarHolidayService, CheckPointService } from '../../shared';
import { NgxSpinnerService } from "ngx-spinner";
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Bank, BANKS ,Customer, CUST} from '../../guard/permanent/guardCheckPoint/demo-data';

@Component({
  selector: 'app-checkin',
  templateUrl: './checkin.component.html',
  styleUrls: ['./checkin.component.css']
})
export class CheckinComponent implements OnInit {
  searchForm: FormGroup;
  openScan = false;
  title: string = 'AGM project';
  latitude!: number;
  longitude!: number;
  zoomLevel: number = 10;
  zoom: number = this.zoomLevel;
  address: string;
  private geoCoder;
  customerList = [];
  customerSearchId;
  dayList = [];

  checkPointModel = {
    'peroid':'06-01-2022',
    'customerList':[]
  };

  @ViewChild('search',{ static: true })
  public searchElementRef!: ElementRef;

  /** list of banks */
  protected customers: Customer[] = CUST;

  /** control for the selected bank */
  public custCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  public custFilterCtrl: FormControl = new FormControl();

  /** list of banks filtered by search keyword */
  public filteredCust: ReplaySubject<Customer[]> = new ReplaySubject<Customer[]>(1);

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private spinner: NgxSpinnerService,
    private customerService: CustomerService,
    private checkPointService: CheckPointService
  ) { 
    this.searchForm = fb.group({
      'month':1,
      'year': [new Date().getFullYear()],
      'customerId': [''],
    });
  }

  ngOnInit() { 
    this.initDayList();
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
      this.getCustomer();
    });
  } 

  initDayList(){
    this.dayList = [];
    
    for(let i=1;i<=31;i++){
      this.dayList.push(i);
    }
    //console.log(this.checkPointModel);
  }

  getCustomer(){
    this.spinner.show();
    this.customerList = [];
    this.customerService.getCustomer2().subscribe(res=>{
      this.customerList = res;
      //console.log(this.customerList);
      this.searchCheckPoint();
      this.customers.push(
        {id:0,name:'เลือกหน่วยงาน'}
      )
      this.customerList.forEach(item=>{
        this.customers.push(
          {id:item.id,name:item.name}
        )
      });

      // set initial selection
    this.custCtrl.setValue(this.customers[0]);

    // load the initial bank list
    this.filteredCust.next(this.customers.slice());

    // listen for search field value changes
    this.custFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCust();
      });
      this.spinner.hide();
    });
  }

  protected filterCust() {
    if (!this.customers) {
      return;
    }
    if(this.custCtrl.value == null){return;}
    // get the search keyword
    console.log(this.custCtrl.value)
    this.customerSearchId = this.custCtrl.value['id'];
    let search = this.custFilterCtrl.value;
    console.log(this.customerSearchId)
    if (!search) {
      this.filteredCust.next(this.customers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredCust.next(
      this.customers.filter(cust => cust.name.toLowerCase().indexOf(search) > -1)
    );
  }

  searchCheckPoint(){
    this.spinner.show();
    this.checkPointModel = {
      'peroid':'06-01-2022',
      'customerList':[]
    };
    //this.customerSearchId = this.searchForm.get('customerId').value;
    //console.log(this.customerSearchId);
    let checkPoint = {
      //'month':this.searchForm.get('month').value,
      //'year':this.searchForm.get('year').value
      //'month':1,
      //'year':2022
      'month':(new Date().getMonth()+1),
      'year':new Date().getFullYear()
    };
    console.log(checkPoint)
    this.checkPointService.getCheckPoint(checkPoint).subscribe(data=>{
      console.log(data);
      if(data.data != null){
        this.checkPointModel = JSON.parse(data.data['json_data']);
        for(let i=0;i<this.checkPointModel.customerList.length;i++){
          let custs = this.customerList.filter(cus => cus.id == this.checkPointModel.customerList[i].customerId);
          if(custs.length>0){
            let tmpEmployeeList = this.checkPointModel.customerList[i].employeeList;
            let tmpSummaryList = this.checkPointModel.customerList[i].summaryList;
            this.checkPointModel.customerList[i] = custs[0];
            //console.log(custs[0])
            this.checkPointModel.customerList[i].customerId = this.checkPointModel.customerList[i].id;
            this.checkPointModel.customerList[i].customerName = this.checkPointModel.customerList[i].name;
            this.checkPointModel.customerList[i].employeeList = tmpEmployeeList;
            this.checkPointModel.customerList[i].summaryList = tmpSummaryList;
            //group position
            let positionGroupList = [];
            for(let j=0;j<custs[0].customerCreditLimit.length;j++){
              let positons = positionGroupList.filter(position => position.code == custs[0].customerCreditLimit[j].code);
              if(positons.length==0){
                positionGroupList.push(custs[0].customerCreditLimit[j]);
              }
            }
            this.checkPointModel.customerList[i].positionGroupList = positionGroupList;
            //console.log(this.checkPointModel)
          }
        }
        console.log(this.checkPointModel)
        this.spinner.hide();
      }else{
        this.spinner.hide();
      }
    });
  }

  saveCheckPoint(){
    //this.disabledSave = true;
    //this.spinner.show();
    let checkPointDb = {
      'peroid':'06-01-2022',
      'customerList':[]
    };
    //this.customerSearchId = this.searchForm.get('customerId').value;
    //console.log(this.customerSearchId);
    console.log(this.checkPointModel)
    let objCompare = null;
    let custsCompare = this.checkPointModel.customerList.filter(cus => cus.id == this.customerSearchId);
       console.log(custsCompare);
      if(custsCompare.length>0){
        objCompare = JSON.parse(JSON.stringify(custsCompare[0]));
      }

    let checkPointSearch = {
      'month':(new Date().getMonth()+1),
      'year':new Date().getFullYear()
    };
    console.log(checkPointSearch)
    let dupOldCustomer = false;
    this.checkPointService.getCheckPoint(checkPointSearch).subscribe(data=>{
      console.log(data);
      if(data.data != null){
        checkPointDb = JSON.parse(data.data['json_data']);
        for(let i=0;i<checkPointDb.customerList.length;i++){
          let custs = this.customerList.filter(cus => cus.id == checkPointDb.customerList[i].customerId);
          if(custs.length>0){
            let tmpEmployeeList = checkPointDb.customerList[i].employeeList;
            let tmpSummaryList = checkPointDb.customerList[i].summaryList;
            checkPointDb.customerList[i] = custs[0];
            console.log(custs[0])
            checkPointDb.customerList[i].customerId = checkPointDb.customerList[i].id;
            checkPointDb.customerList[i].customerName = checkPointDb.customerList[i].name;
            checkPointDb.customerList[i].employeeList = tmpEmployeeList;
            checkPointDb.customerList[i].summaryList = tmpSummaryList;
            //group position
            let positionGroupList = [];
            for(let j=0;j<custs[0].customerCreditLimit.length;j++){
              let positons = positionGroupList.filter(position => position.code == custs[0].customerCreditLimit[j].code);
              if(positons.length==0){
                positionGroupList.push(custs[0].customerCreditLimit[j]);
              }
            }
            checkPointDb.customerList[i].positionGroupList = positionGroupList;
            if(this.customerSearchId == checkPointDb.customerList[i].customerId){
              if(objCompare!=null){
                dupOldCustomer = true;
                checkPointDb.customerList[i] = objCompare;
              }
            }
            console.log(checkPointDb)
          }
        }
        //this.spinner.hide();
        //console.log(this.searchForm.get('month').value)
        if(!dupOldCustomer){
          if(objCompare!=null){
            checkPointDb.customerList.push(objCompare);
          }
        }
        let checkPoint = {
          'month':(new Date().getMonth()+1),
          'year':new Date().getFullYear(),
          'json_data':JSON.stringify(checkPointDb),
        };
        console.log(checkPoint);
        this.spinner.show();
        this.checkPointService.createUpdateCheckPoint(checkPoint).subscribe(data => {
          //this.disabledSave = false;
          console.log(data)
          this.spinner.hide();
          //this.successDialog();
          this.searchCheckPoint();
        }, error => {
          this.spinner.hide();
          console.error(error);
          //this.failDialog(error);
        });
      }else{
        //this.spinner.hide();
      }
    }); 
  }

  testSave(){
    console.log('test save')
    if(this.customerSearchId == '0' || this.customerSearchId == null){
      return;
    }
    let emp = JSON.parse(localStorage.getItem('tisToken'));
    console.log(emp)
    console.log('customer id : ' + this.customerSearchId);
    let date = new Date();
    let day = date.getDate();
    //day = 10;
    console.log('day : ' + day);

    let custs = this.checkPointModel.customerList.filter(cus => cus.customerId == this.customerSearchId);
    if (custs.length > 0) {
        console.log('has customer item')
        for (let i = 0; i < this.checkPointModel.customerList.length; i++) {
          if (this.checkPointModel.customerList[i].customerId == this.customerSearchId) {
            console.log(this.checkPointModel.customerList[i])
            let empcheck = this.checkPointModel.customerList[i].employeeList.filter(e => e.employeeId == emp.id);
            console.log('empcheck',empcheck);
            if(empcheck.length > 0){
              console.log('has old employee')
                for(let j=0;j<empcheck[0].workList.length;j++){
                  //check time
                  let isDay = false;
                  let isNight = false;
                  let currentStatus = '';
                  if(day==empcheck[0].workList[j].date){
                    console.log('match date')
                    currentStatus = 'W';
                    isDay = true;
                    empcheck[0].workList[j].status = currentStatus;
                    empcheck[0].workList[j].day = isDay;
                    empcheck[0].workList[j].nigth = isNight;
                  } 
                }
            }else{
              //case not has employee
              let employee = {
                'employeeId':emp.id,
                'firstName':emp.first_name,
                'lastName':emp.last_name,
                'tel':emp.contacts[0].mobile,
                'position':0,
                'workList':[],
                'optionList':[]
              };
              for(let ii=1;ii<=31;ii++){
                //check time
                let isDay = false;
                let isNight = false;
                let currentStatus = '';
                if(day==ii){
                  currentStatus = 'W';
                  isDay = true;
                } 
                employee.workList.push({
                  'date':ii,
                  'status':currentStatus, //W=ทำงานปกติ,L=ลา,M=ขาด
                  'day':isDay,
                  'nigth':isNight,
                  'waged':550,
                  'wagen':550,
                  'total':0.00,
                  'paidStatus':'',
                  'paidDate':null,
                  'ref1':'',
                  'ref2':'',
                  'ref3':''
                })
              }
              this.checkPointModel.customerList[i].employeeList.push(employee);
            }
            console.log(this.checkPointModel)
          }
        }//end loop customer
    } else{
      //case not has customer
    } 
    this.saveCheckPoint();
  }

  captureImage($event){
    console.log($event)
    this.openScan = !this.openScan;
    //alert($event);
    this.testSave();
  }

  inClicked(){
    this.openScan = true;
  }

  onMapClicked(event: any){
    console.table(event.coords);
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  private setCurrentLocation() { 
    if ('geolocation' in navigator) {
      console.log('have geo location')
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        console.log(this.latitude)
        console.log(this.longitude)
        this.zoom = this.zoomLevel;
        this.getAddress(this.latitude, this.longitude); 
      });
    }else{
      console.log('not have geo location')
    }
  }
  
  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      } 
    });
  }

}
