import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  HeaderComponent,
  MenuLeftComponent,
  LoginInitComponent,
  FooterComponent,
  Constant
} from './shared';
import { DashboardModule } from './dashboard/dashboard.module';
import { CompanyModule } from './setting/company/company.module';
import { DepartmentModule } from './setting/department/department.module';
import { PositionModule } from './setting/position/position.module';
import { MasterDataModule } from './setting/masterData/masterData.module';
import { ProductCategoryModule } from './stock/productCategory/productCategory.module';
import { StockModule } from './stock/stock.module';
import { EmployeeModule } from './employee/employee.module';
import { EmployeeViewModule } from './employee/view/employee_view.module';
import { EmployeeAddModule } from './employee/add/employee_add.module';
import { EmployeeEditModule } from './employee/edit/employee_edit.module';
import { CustomerModule } from './customer/customer.module';
import { CustomerAddModule } from './customer/add/customer_add.module';
import { CustomerViewModule } from './customer/view/customer_view.module';
import { CustomerEditModule } from './customer/edit/customer_edit.module';
import { PasttimeCheckpointModule } from './guard/pasttime/checkpoint/pasttimeCheckpoint.module';
import { ReportPasttimeCheckpointModule } from './report/guard/pasttime/checkpoint/reportPasttimeCheckpoint.module';
import { PasttimePaymentModule } from './guard/pasttime/payment/pasttimePayment.module';
import { WorkScheduleModule } from './guard/permanent/workSchedule/workSchedule.module';
import { ReportSlipModule } from './report/slip/reportSlip.module';
import { CheckTransferPayModule } from './checktransferpay/checktransferpay.module';
import { CalendarHolidayModule } from './setting/calendar/calendar.module';
import { OutsiderModule } from './outsider/outsider.module';
import { ShareModule } from './share/share.module';
import { GuardCheckPointModule } from './guard/permanent/guardCheckPoint/guardCheckPoint.module';
import { ComparisonModule } from './report/comparison/comparison.module';
import { ComparisonRecheckModule } from './report/comparisonRecheck/comparisonRecheck.module';
import { ComplaintModule } from './complaint/complaint.module';
import { ComparisonAuditModule } from './report/comparisonAudit/comparisonAudit.module';
import { InspectionModule } from './setting/inspection/inspection.module';
import { CustomerAuditModule } from './setting/customerAudit/customerAudit.module';
import { CustomerAuditManageModule } from './setting/customerAudit/customerAuditMange/customerAuditManage.module';
import { RentHouseModule } from './setting/rentHouse/rentHouse.module';
import { InsuranceModule } from './setting/insurance/insurance.module';
import { PickStockModule } from './stock/pickStock/pickStock.module';
import { RentHouseManageModule } from './setting/rentHouse/rentHouseManange/rentHouseManage.module';
import { DocumentModule } from './document/document.module';
import { ContractAuditModule } from './setting/contractAudit/contract-audit.module';
import { SickLeaveModule } from './new-guard/sickLeave/sickLeave.module';
import { PersonalLeaveModule } from './new-guard/personalLeave/personalLeave.module';
import { AnnualLeaveModule } from './new-guard/annualLeave/annualLeave.module';
import { WorkingModule } from './new-guard/working/working.module';
import { WarantyInsuranceModule } from './new-guard/warantyInsurance /warantyInsurance.module';
import { ReserveLoanModule } from './new-guard/reserveLoan/reserveLoan.module';
import { CheckinModule } from './new-guard/checkin/checkin.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuLeftComponent,
    FooterComponent,
    LoginInitComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    DashboardModule,
    CompanyModule,
    DepartmentModule,
    PositionModule,
    MasterDataModule,
    ProductCategoryModule,
    StockModule,
    EmployeeModule,
    EmployeeViewModule,
    EmployeeAddModule,
    EmployeeEditModule,
    CustomerModule,
    CustomerAddModule,
    CustomerViewModule,
    CustomerEditModule,
    PasttimeCheckpointModule,
    ReportPasttimeCheckpointModule,
    PasttimePaymentModule,
    WorkScheduleModule,
    ReportSlipModule,
    CheckTransferPayModule,
    CalendarHolidayModule,
    OutsiderModule,
    ShareModule,
    GuardCheckPointModule,
    ComparisonModule,
    ComparisonRecheckModule,
    ComplaintModule,
    InspectionModule,
    ComparisonAuditModule,
    CustomerAuditModule,
    CustomerAuditManageModule,
    RentHouseModule,
    InsuranceModule,
    PickStockModule,
    RentHouseManageModule,
    ContractAuditModule,
    DocumentModule,
    SickLeaveModule,
    PersonalLeaveModule,
    AnnualLeaveModule,
    WorkingModule,
    WarantyInsuranceModule,
    ReserveLoanModule,
    CheckinModule
  ],
  providers: [Constant],
  bootstrap: [AppComponent]
})
export class AppModule { }
