import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Constant } from '../constant';

var jsonResponse: any;
@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  constructor(private http: HttpClient, private constant: Constant) {
  }

  login(employee): Observable<any> {
    return this.http.post<any>(this.constant.API_ENDPOINT + '/employee/login', JSON.stringify(employee),
    {
      headers: new HttpHeaders({
      'Content-Type': 'application/json'
      })
    }).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Get employee list
  getEmployee(): Observable<any> {
    return this.http.get<any>(this.constant.API_ENDPOINT + '/employee');
  }

  // Get employee by id
  getEmployeeById(id): Observable<any> {
    return this.http.get<any>(this.constant.API_ENDPOINT + '/employee/' + id);
  }

  // Update employee by id
  updateEmployee(id, employee): Observable<any> {
    return this.http.put<any>(this.constant.API_ENDPOINT + '/employee/' + id, JSON.stringify(employee),
    {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-User': 'test'
      })
    }).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  backToWork(id, employee): Observable<any> {
    return this.http.patch<any>(this.constant.API_ENDPOINT + '/employee/back-to-work/' + id, JSON.stringify(employee),
    {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-User': 'test'
      })
    }).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  search(request): Observable<any> {
    return this.http.post<any>(this.constant.API_ENDPOINT + '/employee/search', JSON.stringify(request),
    {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-User': 'test'
      })
    }).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Add employee
  addEmployee(employee): Observable<any> {
    return this.http.post<any>(this.constant.API_ENDPOINT + '/employee', JSON.stringify(employee),
    {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-User': 'test'
      })
    }).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Delete employee by id
  delete(id): Observable<any> {
    return this.http.patch<any>(this.constant.API_ENDPOINT + '/employee/' + id,
    {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-User': 'test'
      })
    }).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Get employee by position id
  getEmployeeByPositionId(positionId): Observable<any> {
    return this.http.get<any>(this.constant.API_ENDPOINT + '/employee/position/' + positionId);
  }

  // Error handling
  handleError(error) {
    return throwError(error);
  }
}